import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import FooterComponent from "../common/FooterComponent";
import HeaderComponent from "../common/HeaderComponent";
import LeftMenuComponent from "../common/LeftMenu";
import LoadingComponent from "../common/LoadingComponent";
import { pageNames } from "../helpers/Constants";
import { addCountryService, getStates } from "../service/LocationsService";

const StatesPage = (props) => {
    const [loading, setLoading] = useState(false);
    const [states, setStates] = useState([]);

    const navigate = useNavigate();

    const addCountry = async () => {
        try {
            await addCountryService();
        } catch (error) {
            alert(error);
        }
    }

    const addState = () => {
        navigate(pageNames.addState);
    }

    const edit = (state) => {
        navigate(pageNames.addState, {
            state: {
                isEdit: true,
                stateToEdit: state,
            }
        });
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const statesData = await getStates();
                setStates(statesData);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                alert(error);
            }
        }
        fetchData();
    }, []);

    return (
        <div className="wrapper">

            <HeaderComponent />

            <LeftMenuComponent />

            <div className="content-wrapper">
                <section className="content-header">
                    <h1>
                        States
                        <small>View All States</small>
                    </h1>
                    <ol className="breadcrumb">
                        <li><a href="#"><i className="fa fa-dashboard"></i> Home</a></li>
                        <li className="active">States</li>
                    </ol>
                </section>

                <section className="content">
                    <div className="row">
                        <div className="col-xs-12">
                            <div className="box">
                                {/* <button style={{ marginTop: 20, marginBottom: 20, marginLeft: 10, }}
                                    onClick={addCountry}>
                                    Add Country
                                </button> */}
                                <button style={{ marginTop: 20, marginBottom: 20, marginLeft: 10, }}
                                    onClick={addState}>
                                    Add State
                                </button>
                                <div className="box-body table-responsive no-padding">
                                    {loading ?
                                        <LoadingComponent />
                                        : <table className="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th>Sr. No.</th>
                                                    <th>Country - EN</th>
                                                    <th>Country - JA</th>
                                                    <th>State - EN</th>
                                                    <th>State - JA</th>
                                                    <th>Status</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {states.map((state, index) => (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{state.countryNameEN}</td>
                                                        <td>{state.countryNameJA}</td>
                                                        <td>{state.stateNameEN}</td>
                                                        <td>{state.stateNameJA}</td>
                                                        <td><span className="label label-success">Active</span></td>
                                                        <td>
                                                            <button onClick={() => edit(state)} className="btn btn-primary">
                                                                Edit
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                                )}
                                            </tbody>
                                        </table>}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <FooterComponent />
        </div>
    );
}

export default StatesPage;