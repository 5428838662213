import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import FooterComponent from "../common/FooterComponent";
import HeaderComponent from "../common/HeaderComponent";
import LeftMenuComponent from "../common/LeftMenu";
import LoadingComponent from "../common/LoadingComponent";
import { getSurfboards } from "../service/SurfboardService";
import { pageNames } from "../helpers/Constants";

const SurfboardsPage = (props) => {
    const [loading, setLoading] = useState(false);
    const [surfboards, setSurfboards] = useState([]);

    const navigate = useNavigate();

    const onViewDetails = (surfboard) => {
        navigate(pageNames.surfboardDetail, {
            state: {
                surfboard: surfboard
            }
        });
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const surfboardsData = await getSurfboards();
                setSurfboards(surfboardsData);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                alert(error);
            }
        }
        fetchData();
    }, []);

    return (
        <div className="wrapper">

            <HeaderComponent />

            <LeftMenuComponent />

            <div className="content-wrapper">
                <section className="content-header">
                    <h1>
                        Surfboards
                        <small>View All Surfboards</small>
                    </h1>
                    <ol className="breadcrumb">
                        <li><a href="#"><i className="fa fa-dashboard"></i> Home</a></li>
                        <li className="active">Surfboards</li>
                    </ol>
                </section>

                <section className="content">
                    <div className="row">
                        <div className="col-xs-12">
                            <div className="box">
                                <div className="box-body table-responsive no-padding">
                                    {loading ?
                                        <LoadingComponent />
                                        : <table className="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th>Sr. No.</th>
                                                    <th>Submitted By</th>
                                                    <th>Price</th>
                                                    <th>Brand</th>
                                                    <th>Uploaded On</th>
                                                    <th>Is Available</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {surfboards.map((surfboard, index) => (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{surfboard.user.name}</td>
                                                        <td>{surfboard.sellingPrice}</td>
                                                        <td>{surfboard.brandName}</td>
                                                        <td>{new Date(surfboard.createdAt.seconds * 1000).toString()}</td>
                                                        <td>{surfboard.isAvailable ? "Yes" : "No"}</td>
                                                        <td>
                                                            <button onClick={() => onViewDetails(surfboard)} className="btn btn-primary">
                                                                View Details
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                                )}
                                            </tbody>
                                        </table>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <FooterComponent />
        </div>
    );
}

export default SurfboardsPage;