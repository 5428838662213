import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import FooterComponent from "../common/FooterComponent";
import HeaderComponent from "../common/HeaderComponent";
import LeftMenuComponent from "../common/LeftMenu";
import LoadingComponent from "../common/LoadingComponent";
import { isNullOrEmpty } from "../helpers/HelperFunctions";
import { addState, updateState } from "../service/LocationsService";

const AddStatePage = (props) => {
    const [loading, setLoading] = useState(false);
    const [stateNameEN, setStateNameEN] = useState("");
    const [stateNameJA, setStateNameJA] = useState("");
    const [stateNameENError, setStateNameENError] = useState("");
    const [stateNameJAError, setStateNameJAError] = useState("");
    const [stateToEditId, setStateToEditId] = useState(null);
    const [isEdit, setIsEdit] = useState(false);
    const params = useLocation()

    const addStatePressed = async () => {
        if (isNullOrEmpty(stateNameEN)) {
            setStateNameENError("Please enter State name in English");
        } else if (isNullOrEmpty(stateNameJA)) {
            setStateNameJAError("Please enter State name in Japanese");
        } else {
            try {
                setLoading(true);
                let message = ""
                if (isEdit) {
                    const success = await updateState(stateToEditId, stateNameEN, stateNameJA);
                    message = "State updated successfully"
                } else {
                    const success = await addState(stateNameEN, stateNameJA);
                    message = "State added successfully"
                    setStateNameEN("");
                    setStateNameJA("");
                }
                setLoading(false);
                alert(message);
            } catch (error) {
                setLoading(false);
                alert(error);
            }
        }
    }

    useEffect(() => {
        if (params && params.state && params.state.isEdit) {
            setIsEdit(true);
            const stateToEdit = params.state.stateToEdit
            setStateToEditId(stateToEdit.stateId);
            setStateNameEN(stateToEdit.stateNameEN);
            setStateNameJA(stateToEdit.stateNameJA);
        }
    }, [])

    return (
        <div className="wrapper">

            <HeaderComponent />

            <LeftMenuComponent />

            <div className="content-wrapper">
                <section className="content-header">
                    <h1>
                        {isEdit ? "Update State" : "Add New State"}
                    </h1>
                    <ol className="breadcrumb">
                        <li><a href="#"><i className="fa fa-dashboard"></i> Home</a></li>
                        <li><a href="#">States</a></li>
                        <li className="active">Add/Edit New State</li>
                    </ol>
                </section>

                <section className="content">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="box box-primary">
                                <div className="box-header">
                                    <h3 className="box-title">Fill the form below</h3>
                                </div>

                                <div className="box-body">
                                    <div className="form-group">
                                        {/* Hard coded value for now */}
                                        <label>Country Name</label>
                                        <select className="form-control">
                                            <option>Japan</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label>State Name - EN</label>
                                        <input type="text" className="form-control"
                                            placeholder="State Name in English"
                                            onChange={(event) => {
                                                setStateNameEN(event.target.value);
                                                setStateNameENError('');
                                            }}
                                            value={stateNameEN} />
                                        <p className="error-text">{stateNameENError}</p>
                                    </div>
                                    <div className="form-group">
                                        <label>State Name - JA</label>
                                        <input type="text" className="form-control"
                                            placeholder="State Name in Japanese"
                                            onChange={(event) => {
                                                setStateNameJA(event.target.value);
                                                setStateNameJAError('');
                                            }}
                                            value={stateNameJA} />
                                        <p className="error-text">{stateNameJAError}</p>
                                    </div>
                                </div>

                                <div className="box-footer">
                                    {loading ?
                                        <LoadingComponent />
                                        :
                                        <button onClick={addStatePressed} className="btn btn-primary btn-block btn-flat">
                                            {isEdit ? "Update State" : "Add State"}
                                        </button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <FooterComponent />
        </div>
    );
}

export default AddStatePage;