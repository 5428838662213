import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import FooterComponent from "../common/FooterComponent";
import HeaderComponent from "../common/HeaderComponent";
import LeftMenuComponent from "../common/LeftMenu";
import LoadingComponent from "../common/LoadingComponent";
import { getUsers } from "../service/UserService";
import { UserTypes, pageNames } from "../helpers/Constants";
import { CSVLink } from "react-csv";

const headers = [
    { label: "Id", key: "id" },
    { label: "Name", key: "name" },
    { label: "Username", key: "username" },
    { label: "User Type", key: "userType" },
    { label: "Email", key: "email" },
    { label: "Phone", key: "phoneNumber" },
];

const UsersPage = (props) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [dataForCsv, setDataForCsv] = useState([]);

    const onViewDetails = (user) => {
        navigate(pageNames.userDetail, {
            state: {
                user: user
            }
        });
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const usersData = await getUsers();
                setUsers(usersData);

                const csvUsers = [];
                for (let i = 0; i < usersData.length; ++i) {
                    const user = usersData[i];
                    const obj = {
                        id: user.id,
                        name: user.name,
                        username: user.username,
                        userType: user.userType,
                        email: user.email ? user.email : "",
                        phoneNumber: user.phoneNumber ? user.phoneNumber: ""
                    }
                    csvUsers.push(obj);
                }
                setDataForCsv(csvUsers);

                setLoading(false);
            } catch (error) {
                setLoading(false);
                alert(error);
            }
        }
        fetchData();
    }, []);

    return (
        <div className="wrapper">

            <HeaderComponent />

            <LeftMenuComponent />

            <div className="content-wrapper">
                <section className="content-header">
                    <h1>
                        Users
                        <small>View All Users</small>
                    </h1>
                    {!loading ?
                        <CSVLink
                            data={dataForCsv}
                            headers={headers}
                            filename={"surfrack-users.csv"}
                            className="btn btn-primary"
                            style={{ marginTop: 10 }}>
                            Export to CSV
                        </CSVLink>
                        : <div />
                    }
                    <ol className="breadcrumb">
                        <li><a href="#"><i className="fa fa-dashboard"></i> Home</a></li>
                        <li className="active">Users</li>
                    </ol>
                </section>

                <section className="content">
                    <div className="row">
                        <div className="col-xs-12">
                            <div className="box">
                                <div className="box-body table-responsive no-padding">
                                    {loading ?
                                        <LoadingComponent />
                                        : <table className="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th>Sr. No.</th>
                                                    <th>Name</th>
                                                    <th>Is Business</th>
                                                    <th>Uploaded Surfboards</th>
                                                    <th>Email</th>
                                                    <th>Phone</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {users.map((user, index) => (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{user.name}</td>
                                                        <td>{user.userType === UserTypes.BUSINESS ? 'Yes' : 'No'}</td>
                                                        <td>{user.surfboards ? user.surfboards.length : '0'}</td>
                                                        <td>{user.email}</td>
                                                        <td>{user.phoneNumber}</td>
                                                        <td>
                                                            <button onClick={() => onViewDetails(user)} className="btn btn-primary">
                                                                View Details
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                                )}
                                            </tbody>
                                        </table>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <FooterComponent />
        </div>
    );
}

export default UsersPage;